import { Component, Mixins } from 'vue-property-decorator'
import { URLManager } from '@/services/Managers'
import { Toast } from '@/mixins'
import { PurchaseModule } from '@/store/modules'
import { Purchase } from '@/api'
import { PaymentProviders } from '@/types/enums'

enum PaymentState {
  SUCCESS = 'success',
  ERROR = 'error'
}

@Component
export default class PaymentModule extends Mixins(Toast) {
  @PurchaseModule.Getter selectedPurchaseAmount

  async handlePaymentTransaction(provider: PaymentProviders) {
    let data: Nullable<{ checkoutUrl: string }> = null

    // @ts-ignore
    if (!this.activateDigitalContent) return this.purchaseCheckBoxError = true;

    // @ts-ignore
    if (!this.selectedPurchaseAmount) return this.error = true;

    try {
      data = await Purchase.makePayment({
        provider: provider.toLowerCase(),
        price: this.selectedPurchaseAmount.price
      })

      if (!data) {
        this.handlePaymentError()
        return
      }

      window.open(data.checkoutUrl, '_blank')
    } catch (e) {
      this.handlePaymentError()
      console.error('Error on Payment Transaction', e)
    }
  }

  async handlePaymentRedirection() {
    const paymentQueryParam = this.$route.query.payment

    if (paymentQueryParam) {
      paymentQueryParam === PaymentState.SUCCESS
        ? await this.handlePaymentSuccess()
        : await this.handlePaymentError()
    }
  }

  async handlePaymentSuccess() {
    try {
      // @ts-ignore
      await this.getUser()

      this.showSuccessToast({
        title: 'Successfully purchased!',
        message: `You successfully purchased OC! Thanks for your support!`
      })

      URLManager.updateURLParams('payment')
    } catch(e) {
      this.handlePaymentError()
    }
  }

  async handlePaymentError() {
    this.showErrorToast({
      title: 'An error occurred',
      message: `The purchase has been canceled or an error occurred.`
    })
  }
}
