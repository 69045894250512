
















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import 'animate.css'
import { PurchaseModule, UserModule } from '@/store/modules'
import { themeConfig } from '@/config/themeConfig'
import Payment from '@/mixins/Payment'
import { Purchase } from '@/api'

@Component({
  components: {
    FreePurchaseAmountModal: () => import('@/components/Shop/FreePurchaseAmountModal.vue'),
    PurchaseAmountCard: () => import('@/components/Shop/PurchaseAmountCard.vue'),
    TransactionHistory: () => import('@/components/Shop/TransactionHistory.vue')
  }
})
export default class PurchasePage extends Mixins(Payment) {
  @PurchaseModule.State currentAmountSelected
  @PurchaseModule.State promotion
  @PurchaseModule.State purchaseAmounts
  @PurchaseModule.Mutation setCurrentAmountSelected
  @PurchaseModule.Action getCurrentPromotion
  @UserModule.Action getUser

  links = themeConfig.app.links

  invoice = false
  activateDigitalContent = false
  purchaseCheckBoxError = false
  transactionLoading = false
  error = false
  amount: Nullable<number> = null
  isCryptoEnabled = true
  bitcoinModal = false
  paymentProviders = []

  @Watch('currentAmountSelected')
  onCurrentAmountSelectedChange() {
    if (this.error) this.error = false
  }

  async mounted() {
    await this.handlePaymentRedirection()

    this.paymentProviders = (await Purchase.getPaymentMethods()).enabledPaymentMethods

    await this.getCurrentPromotion()

    this.$root.$on('setTransactionLoading', value => this.setTransactionLoading(value))
    this.$root.$on('setAmount', amount => this.setAmount(amount))
    this.$root.$on('purchaseError', err => this.setError(err))

    setTimeout(async () => {
      await this.getUser()
    }, 1000)
  }

  beforeDestroy() {
    this.setCurrentAmountSelected(null)
    this.$root.$off('setTransactionLoading', value => this.setTransactionLoading(value))
    this.$root.$off('setAmount', amount => this.setAmount(amount))
    this.$root.$off('purchaseError', err => this.setError(err))
  }

  setTransactionLoading(value: boolean) {
    this.transactionLoading = value
  }

  setAmount(amount: Nullable<number>) {
    this.amount = amount
  }

  setError(error: boolean) {
    this.error = error
  }
}
